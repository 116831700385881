import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '@builder/run-engine';
import { IBlock } from '../../../framework/src/IBlock'
import { Message } from '../../../framework/src/Message'

type AlertButton = {
  type: string;
  label: string;
  message: Message;
}

export interface Props {
}

interface S {
  isVisible: boolean,
  title: string;
  body: string;
  buttons: AlertButton[];

}

interface SS { }

export default class AlertBlockController extends BlockComponent<Props, S, SS> {
  _isMounted = false;

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

    ]
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isVisible: false,
      title: "",
      body: "",
      buttons: []
    }

    this.receive = this.receive.bind(this);
  }

  async receive(_: string, message: Message) {

    if (getName(MessageEnum.AlertMessage) === message.id) {

      let title = message.getData(getName(MessageEnum.AlertTitleMessage));
      let body = message.getData(getName(MessageEnum.AlertBodyMessage));

      let btnPositiveText = message.getData(getName(MessageEnum.AlertButtonPositiveText));
      let btnPositiveMessage = message.getData(getName(MessageEnum.AlertButtonPositiveMessage));

      let btnNegativeText = message.getData(getName(MessageEnum.AlertButtonNegativeText));
      let btnNegativeMessage = message.getData(getName(MessageEnum.AlertButtonNegativeMessage));

      let btnNeutralText = message.getData(getName(MessageEnum.AlertButtonNeutralText));
      let btnNeutralMessage = message.getData(getName(MessageEnum.AlertButtonNeutralMessage));

      const buttons: AlertButton[] = [];

      if (btnNeutralText) {
        const btnNeutral = {
          type: "neutral",
          label: btnNeutralText,
          message: btnNeutralMessage,
        };
        buttons.push(btnNeutral);
      }

      if (btnPositiveText) {
        const btnPositive = {
          type: "positive",
          label: btnPositiveText,
          message: btnPositiveMessage,
        };
        buttons.push(btnPositive);
      }

      if (btnNegativeText) {
        const btnNegative = {
          type: "negative",
          label: btnNegativeText,
          message: btnNegativeMessage,
        };
        buttons.push(btnNegative);
      }

      if (!this._isMounted) {
        return;
      }

      this.setState({
        isVisible: true,
        title: title,
        body: body,
        buttons,
      })

    }
  }

  onButtonPress(button: AlertButton) {
    if (button.message) {
      this.send(button.message);
    } else {
      this.setState({ isVisible: false });
    }
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    this._isMounted = true;
  }
}
