// Protected Area Start
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Protected Area End

// Protected Area Start
export const sendRunEngineNavigationEvent = (
  sendMessage: (from: string, message: Message) => void,
  screenName: string,
  blockName: string
) => {
  const navMessage = new Message(getName(MessageEnum.NavigationMessage));
  navMessage.addData(MessageEnum.NavigationTargetMessage, screenName);

  sendMessage(blockName, navMessage);
};
// Protected Area End
