// Protected Area Start
import { MessageEnum as StandardMessageEnum, getName as getStandardName } from '@builder/run-engine';
// Protected Area End


// Define your custom messages in a separate enum
enum CustomMessageEnum {
  CustomMessageOne = 'CustomMessageOne',
}

// Protected Area Start
// Create a merged MessageEnum by combining standard and custom enums
const MessageEnum = {
  ...StandardMessageEnum,
  ...CustomMessageEnum,
};

// Define a type for MessageEnum that combines the standard and custom values
export type MessageEnumType = (typeof StandardMessageEnum)[keyof typeof StandardMessageEnum] | (typeof CustomMessageEnum)[keyof typeof CustomMessageEnum];

// Function to get the name of any message, including custom and standard messages
export const getName = (myEnum: MessageEnumType): string => {
  // Check if the enum value exists in CustomMessageEnum
  if (Object.values(CustomMessageEnum).includes(myEnum as CustomMessageEnum)) {
    return CustomMessageEnum[myEnum as keyof typeof CustomMessageEnum];
  }
  // Otherwise, fallback to the standard message enum (using enum values)
  return getStandardName(myEnum as typeof StandardMessageEnum[keyof typeof StandardMessageEnum]);
};

// Export both the value and type of MessageEnum
export default MessageEnum;
// Protected Area End
